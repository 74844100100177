
import { defineComponent } from "vue";

export default defineComponent({
	name:"App",
	methods:{
		handleSelect : function (index:string) {
			this.$router.push(index)
		}
	}
})


